<template>
  <div class="register-wrap">
    <div class="container">
      <div class="lable">{{ $t('login.enterprise_registration') }}</div>
      <!-- 单位注册 -->
      <div class="detail">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
          size="large"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 13 }"
          v-if="!successRegister"
        >
        <!-- 单位名称 -->
          <a-form-item :label="$t('login.enterprise_name')" name="enterpriseName">
            <a-input
              v-model:value="formState.enterpriseName"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
          </a-form-item>
          <!-- 姓名 -->
          <a-form-item :label="$t('login.name')" name="realName">
            <a-input
              v-model:value="formState.realName"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
          </a-form-item>
          <!-- 账号 -->
          <a-form-item :label="$t('login.account')" name="account">
            <a-input
              v-model:value="formState.account"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
          </a-form-item>
          <!-- 密码 -->
          <a-form-item :label="$t('login.password')" name="password">
            <a-input-password
              v-model:value="formState.password"
              type="password"
              size="large"
              :placeholder="$t('LB_QA_PleaseEnter')"
            />
          </a-form-item>
          <!-- 手机号 -->
          <a-form-item :label="$t('login.mobile')" name="mobile">
            <a-input
              v-model:value="formState.mobile"
              :disabled="disabled"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
          </a-form-item>
          <!-- 验证码 -->
          <a-form-item :label="$t('login.code')" name="code">
            <a-input-search
              v-model:value="formState.code"
              size="large"
              :placeholder="$t('LB_QA_PleaseEnter')"
              @search="getCode(formState.mobile)"
            >
              <template #enterButton>
                <a-button
                  type="primary"
                  style="background: #faad14; border-color: #faad14"
                  :disabled="disabled"
                  >{{ codeText }}</a-button
                >
              </template>
            </a-input-search>
          </a-form-item>
          <!-- 邮箱 -->
          <a-form-item :label="$t('login.email')" name="email">
            <a-input
              v-model:value="formState.email"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 13, offset: 6 }" name="checked">
            <div>
              <a-checkbox-group v-model:value="formState.checked">
                <a-checkbox value="1"></a-checkbox>
              </a-checkbox-group>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('login.agree') }}
              <!-- 同意 -->
              <router-link to="/mine/useragreement"
                >{{ $t('login.termsOfService') }}</router-link
              >
              <!-- 《Traingo服务条款》 -->
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <router-link to="/mine/private"
                >{{ $t('login.legalNotice') }}</router-link
              >
              <!-- 《法律声明和隐私政策》 -->
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 9 }">
            <span class="btn"
              ><a-button
                type="primary"
                block
                @click.prevent="onSubmit"
                size="large"
                >{{ $t('login.submit') }}</a-button
              ></span
            >
            <!-- 同意并注册 -->
            <span class="btn"
              ><a-button
                @click.prevent="toLogin"
                block
                size="large"
                style="margin-left: 20px"
                >{{ $t('login.to_login') }}</a-button
              ></span
            >
            <!-- 去登录 -->
          </a-form-item>
        </a-form>
        <!-- 恭喜您，注册成功！ -->
        <a-result
          status="success"
          :title="$t('login.register_success_title')"
          :sub-title="$t('login.register_success_tip')"
          v-else
        >
        <!-- 您的账号已经注册成功，快去登录平台试试吧。 -->
          <template #extra>
            <a-button key="console" type="primary" @click="toLogin"
              >{{ $t('login.to_login') }}</a-button
            >
            <!-- 去登录 -->
          </template>
        </a-result>
      </div>
    </div>
    <div class="copyright">
      <span v-if="studyCopyright" style="padding: 0 5px">{{
        studyCopyright
      }}</span>
      <a
        :href="`https://beian.miit.gov.cn${
          newWindow == 1 ? '?ddtab=true' : ''
        }`"
        :target="newWindow == 1 ? '_blank' : '_self'"
        v-if="icpNo"
      >
        {{ icpNo }}
      </a>
      <a
        v-if="ncNo"
        :target="newWindow == 1 ? '_blank' : '_self'"
        :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${ncNo}${
          newWindow == 1 ? '&ddtab=true' : ''
        }`"
      >
        公网安备 {{ ncNo }}号
      </a>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, ref, watch, toRefs } from "vue";
import { useStore } from "vuex";
import {
  validatorMobile,
  validatorEmail,
  validatorPassword,
} from "@/utils/formRules";
import { sendCode, registerEnterprise } from "@/api/user";
import { getCurrentInstance } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { appendJs } from "@/utils/tools.js";
import { currentHost } from "@/utils/request.js";

export default {
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      studyCopyright: "",
      icpNo: "",
      ncNo: "",
    });

    watch(
      () => store.getters.platformConfig,
      (val) => {
        state.studyCopyright = val.studyCopyright;
        state.icpNo = val.icpNo;
        state.ncNo = val.ncNo;
        appendJs("Track", val.studyTrack);
      },
      { immediate: true, deep: true }
    );

    const formRef = ref();
    const formState = reactive({
      enterpriseName: "",
      realName: "",
      account: "",
      password: "",
      mobile: "",
      code: "",
      email: "",
      checked: [],
    });
    const codeText = ref($t('login.get_code'));
    // 获取验证码
    const disabled = ref(false);
    const successRegister = ref(false);
    let validatorChecked = async (rule, value) => {
      if (value != "1") {
        return Promise.reject($t('login.readAndTick', []));
        // 请阅读并勾选《Traingo服务条款》|《法律声明和隐私政策》
      }
      return Promise.resolve();
    };
    const rules = {
      enterpriseName: [
        {
          required: true,
          message: $t('login.enterprise_name_p'),
          // 请输入单位名称
          trigger: "change",
        },
      ],
      realName: [
        {
          required: true,
          message: $t('login.name_p'),
          // 请输入姓名
          trigger: "change",
        },
      ],
      account: [
        {
          required: true,
          message: $t('login.account_p'),
          // 请输入账号
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: $t('login.password_p'),
          // 请输入密码
          trigger: "change",
        },
        {
          validator: validatorPassword,
          trigger: "change",
        },
      ],
      mobile: [
        {
          required: true,
          message: $t('login.请输入手机号'),
          // 请输入手机号
          trigger: "change",
        },
        {
          validator: validatorMobile,
          trigger: "change",
        },
      ],
      code: [
        {
          required: true,
          message: $t('login.code_p'),
          // 请输入验证码
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: $t('login.email_p'),
          // 请输入邮箱
          trigger: "change",
        },
        {
          validator: validatorEmail,
          trigger: "change",
        },
      ],
      checked: [
        {
          validator: validatorChecked,
          trigger: "change",
        },
      ],
    };

    const getCode = (searchValue) => {
      if (searchValue == "" || !/^1[3456789]\d{9}$/.test(searchValue)) {
        proxy.$message.error($t('login.mobile_err'));
        // 手机号不正确
        return false;
      }
      disabled.value = true;
      codeText.value = 60;
      let timer = setInterval(() => {
        codeText.value = parseInt(codeText.value) - 1;
        if (codeText.value == 0) {
          disabled.value = false;
          codeText.value = $t('login.get_code');
          // 获取验证码
          clearInterval(timer);
        }
      }, 1000);
      sendCode({
        mobile: searchValue,
        site: currentHost,
      }).then((res) => {
        if (res.ret == 0) {
          proxy.$message.success(res.msg); //发送验证码成功，请查收！
        }
      });
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          registerEnterprise({
            enterpriseName: formState.enterpriseName,
            realName: formState.realName,
            account: formState.account,
            password: proxy.$getRsaCode(formState.password),
            mobile: formState.mobile,
            code: formState.code,
            email: formState.email,
            site: currentHost,
            platform: 1,
          }).then((res) => {
            if (res.ret == 0) {
              successRegister.value = true;
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const toLogin = () => {
      router.push({ path: `/login` });
    };

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      formRef,
      formState,
      codeText,
      disabled,
      rules,
      getCode,
      onSubmit,
      successRegister,
      toLogin,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/register.less";
.copyright {
  width: 960px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  color: #899299;
  ::v-deep(a) {
    color: #899299 !important;
  }
}
</style>
